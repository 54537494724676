import firebase from 'firebase/app'
import 'firebase/auth'
import 'firebase/firestore'
//import firebase from 'firebase'

const firebaseConfig = {
    apiKey: "AIzaSyDxD_Yiy9a4imNyBPG99HBOvd7K0Hlmlws",
    authDomain: "kuricrimp-app.firebaseapp.com",
    databaseURL: "https://kuricrimp-app-default-rtdb.firebaseio.com",
    projectId: "kuricrimp-app",
    storageBucket: "kuricrimp-app.appspot.com",
    messagingSenderId: "183882400840",
    appId: "1:183882400840:web:7b9468b2cd4a002e2d047d",
    measurementId: "G-XYZ2QS89B8"
};

firebase.initializeApp(firebaseConfig)

// utils
const db = firebase.firestore()
const auth = firebase.auth()

// export utils/refs
export {
    db,
    auth
}
